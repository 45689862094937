import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer({ isAuthenticated }) {
  return (
    <footer className="footer">
      <div className="footer-links">
        <Link to="/deactivate" className="footer-link">deactivate</Link>
        <Link to="/unsubscribe" className="footer-link">unsubscribe</Link>
        {isAuthenticated && (
          <Link to="/clear-likes" className="footer-link">clear likes</Link>
        )}
      </div>
      <p className="footer-contact">
        inquiries: <a href="mailto:contact@maroonmatch.com" className="footer-link">contact@maroonmatch.com</a>
      </p>
    </footer>
  );
}

export default Footer;
