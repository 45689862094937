import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

function Header({ isAuthenticated, isDeactivated, handleLogout }) {
  const navigate = useNavigate();

  const onLogout = async () => {
    try {
      await handleLogout();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <header className="header-container">
      <div className="header-logo">
        <Link to="/" className="header-logo-link">
          maroon match
        </Link>
      </div>

      {/* Navigation and Authentication Container */}
      <nav>
        <div className="header-nav-auth">
          {/* Navigation Links */}
          <ul className="header-nav-links">
            <li><Link to="/about">about</Link></li>
            <li><Link to="/faq">faq</Link></li>
            <li><Link to="/contact">contact</Link></li>
            <li>
              {isAuthenticated ? (
                <button
                  type="button"
                  onClick={onLogout}
                  className="header-logout-link"
                >
                  log out
                </button>
              ) : (
                <a href="/api/login" className="header-login-link">
                  log in
                </a>
              )}
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
